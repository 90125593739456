import React, { useContext, useEffect, useState } from 'react'
import { Card, CardBody } from 'reactstrap'
import axios from 'axios'

function CredAudit({appt_sys}) {

    const [loading, setLoading] = useState(false)
    const [audits, setAudits] = useState([])
    const api_url = process.env.REACT_APP_API_URL


    useEffect(() => {
        fetch()
    }, [])


    const fetch = async () => {
        let obj = {
            'm_key': appt_sys.m_key
        }
        console.log('Object in uipdate', obj)
        setLoading(true)
        try {
            const token = localStorage.getItem("idToken")
            const headers = { Authorization: `Bearer ${token}` }
    
            const response = await axios.post(api_url + '/appt-sys/audits', obj, {headers})
    
            if (response.status >= 200 && response.status < 300) {
              setAudits(response.data)
              setLoading(false)

            }
        } catch (e) {
            setLoading(false)
            console.log(e)
        }
    }

    console.log(audits)

    return (
        <Card>
            <CardBody>
                <h5 className="text-primary">Audit History</h5>
                {loading && <div>Loaidng....</div>}
                {!loading && audits.length>0 && <div>audit data received</div>}
            </CardBody>
        </Card>
    )
}

export default CredAudit